<template>
  <v-card>
    <v-card-title>Employee</v-card-title>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="employeeForm.first_name"
              :error-messages="errors.first_name"
              label="First Name"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="employeeForm.last_name"
              :error-messages="errors.last_name"
              label="Last Name"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="employeeForm.title"
              label="Title/Position"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="employeeForm.roles"
              :error-messages="errors.roles"
              :items="roles"
              hide-details="auto"
              label="Role"
              item-text="name"
              item-value="id"
              outlined
              dense
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="employeeForm.can_login">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="employeeForm.email"
              :error-messages="errors.email"
              label="Email"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="employeeForm.password"
              :error-messages="errors.password"
              label="Password"
              outlined
              dense
              hide-details="auto"
              type="password"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="employeeForm.password_confirmation"
              label="Confirm Password"
              outlined
              dense
              hide-details="auto"
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-checkbox
              v-model="employeeForm.can_login"
              hide-details="auto"
              label="System User"
              :true-value="1"
              :false-value="0"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="employeeForm.is_active"
              hide-details="auto"
              label="Active"
              :true-value="1"
              :false-value="0"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="saving"
              @click="saveForm"
            >
              Save
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="$router.back()"
            >
              Back
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="resetForm"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
        </v>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import axios from '@axios'
import useEmployees from './useEmployees'
import router from '@/router'

const formInit = () => ({
  first_name: '',
  last_name: '',
  email: '',
  can_login: 0,
  title: '',
  is_active: 1,
  roles: [],
  password: null,
  password_confirmation: null,
})

export default {
  setup() {
    const saving = ref(false)
    const errors = ref([])
    const employeeForm = ref(formInit())
    const {
      employee, getEmployee, roles, getRoles,
    } = useEmployees()
    const resetForm = () => {
      employeeForm.value = formInit()
    }

    onMounted(() => {
      getRoles()
    })

    if (router.currentRoute.params.id) {
      // fetch employee from the server
      saving.value = true
      getEmployee(router.currentRoute.params.id).then(response => {
        const { data } = response
        employeeForm.value = data

        // alter data for user roles
        employeeForm.value.roles = data.roles.map(val => val.id)

        saving.value = false
      })
    }
    const saveForm = () => {
      saving.value = true
      if (employeeForm.value.id) {
        // update
        axios
          .put(`/users/${employeeForm.value.id}`, employeeForm.value)
          .then(_ => {
            saving.value = false
            router.back()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        // new
        axios
          .post('/users', employeeForm.value)
          .then(_ => {
            saving.value = false
            router.back()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    return {
      // variables
      errors,
      saving,

      // functions
      saveForm,

      // useEmployees
      employee,
      roles,
      getEmployee,
      employeeForm,

      resetForm,
    }
  },
}
</script>
